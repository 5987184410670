import { TextProps } from "@/types/components/front/text";
import MoveWordsToNewLine from "@/Helpers/MoveWordsToNewLine";

const Text = ({ heading, bold, className = "", color, children }: TextProps) => {
    let textColor = 'i-black';
    let availableColors = 'text-i-blue text-i-blue-2 text-i-navy text-i-indigo text-i-gold text-i-black text-i-gray text-i-gray-2 text-i-light-gray text-i-yellow text-i-green text-i-red text-i-black-2'
    
    if(color) {
        textColor = color;
    }
    
    switch (heading) {
        case "h1":
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} md:text-40 md:leading-12 text-32 leading-10 font-bold ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "h2":
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} md:text-32 md:leading-10 text-24 leading-8 ${
                                bold ? "font-bold" : "font-medium"
                            } ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "h3":
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} md:text-24 md:leading-8 text-20 leading-7 ${
                                bold ? "font-bold" : "font-medium"
                            } ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "h4":
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} md:text-20 md:leading-7 text-18 leading-6 ${
                                bold ? "font-bold" : "font-medium"
                            } ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "h5":
            // Not defined, same as h4
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} md:text-20 md:leading-7 text-18 leading-6 ${
                                bold ? "font-bold" : "font-medium"
                            } ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "h6":
            // Not defined, same as h4
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} md:text-20 md:leading-7 text-18 leading-6 ${
                                bold ? "font-bold" : "font-medium"
                            } ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "div":
            textColor = 'i-gray';
            if(color) {
                textColor = color;
            }
            return (
                <MoveWordsToNewLine>
                    <div className={`text-${textColor} text-16 leading-6 ${bold ? "font-semibold" : ""} ` + className}>
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "label":
            textColor = 'i-gray';
            if(color) {
                textColor = color;
            }
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} text-14 leading-4 ${
                                bold ? "font-semibold" : ""
                            } ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        case "estateCarousel":
            return (
                <MoveWordsToNewLine>
                    <div
                        className={
                            `text-${textColor} md:text-[29px] md:leading-10 text-24 leading-8 ${
                                bold ? "font-bold" : "font-medium"
                            } ` + className
                        }
                    >
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
        default:
            textColor = 'i-gray';
            if(color) {
                textColor = color;
            }
            return (
                <MoveWordsToNewLine>
                    <div className={`text-${textColor} text-16 leading-6 tracking-[0.012em] ${bold ? "font-semibold" : ""} ` + className}>
                        {children}
                    </div>
                </MoveWordsToNewLine>
            );
    }
};

export default Text;
