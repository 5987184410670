import DOMPurify from "dompurify";
import parse from "html-react-parser";

const sanitizeHTML = (html) => {

    const safeHTML = DOMPurify.sanitize(html, {

        ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'br', 'i', 'u', 'em', 'p', 'sup', 'li', 'ul', 'span', 'a', 'div', 'b'],
        ALLOWED_ATTR: ["style", "href", "title"],
      
      });
      
    
    return (
        parse(safeHTML)
    );
}

export default sanitizeHTML;