import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "@inertiajs/react";
import Links from "./LinksArrayFront";
import { MouseEvent, useState } from "react";
import { NavMenuProps } from "@/types/components/navMenu/navMenu";
import WebpPicture from "../WebpPicture";

const NavMenu = ({isMenuVisible, toggleMenu, links}: NavMenuProps) => {

    return (
        <div className={`menu-wrapper z-50 w-full fixed top-0 left-0 h-full transition-transform duration-300 ${isMenuVisible ? 'translate-x-0' : 'translate-x-full'}`}>
            <div className="overlay w-full h-full bg-i-black bg-opacity-[98]" onClick={(e) => toggleMenu(e)}>
                <div className="xl:w-3/5 md:w-2/5 h-full">
                <div className="xl:w-2/3 w-full h-full flex-col flex p-10">

                    <div className="w-full h-1/3 pb-3">
                        <WebpPicture src="/storage/img/menu/Frame_94.jpg" alt="" className="w-full h-full object-cover" />
                    </div>

                    <div className="w-full h-2/3 pt-3 flex gap-6">
                        <div className="w-1/2">
                            <WebpPicture src="/storage/img/menu/Frame_495.jpg" alt="" className="w-full h-full object-cover" />
                        </div>
                        <div className="w-1/2">
                            <WebpPicture src="/storage/img/menu/Frame_496.jpg" alt="" className="w-full h-[54%] object-cover pb-3" />
                            <WebpPicture src="/storage/img/menu/Frame_494.jpg" alt="" className="w-full h-[46%] object-cover pt-3" />
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
            <div className="nav-wrapper xl:w-2/5 md:w-3/5 w-full bg-i-navy h-full absolute top-0 right-0 text-right flex flex-col">
                <div className="md:px-24 px-6">
                    <button className="mt-4 lg:mb-[72px] mb-6" onClick={(e) => toggleMenu(e)} title="Nawigacja">
                        <Icon
                            icon={"ic:round-close"}
                            width={48}
                            height={48}
                            className="text-white hover:text-i-gold hover:rotate-180 duration-300 ease-in-out transition-all"
                        />
                    </button>
                </div>
                <nav className="overflow-y-auto md:px-24 px-6 pb-8">
                    <ul>
                        {links.map((link, index) => (
                            <li className="mt-6" key={index}>
                                {link.target == 'blank' ?
                                    <a 
                                        target={'_blank'} 
                                        href={link.url}
                                        className="font-bold text-i-gold text-32 leading-10 hover:text-i-gold transition-all"
                                    >
                                        {link.title}
                                    </a>
                                 :
                                <Link
                                    href={link.url}
                                    className="font-bold text-white text-32 leading-10 hover:text-i-gold transition-all"
                                >
                                    {link.title}
                                </Link>
                                }
                                {link.subNavi ? (
                                    <ul className="mt-2">
                                        {link.subNavi.map((subLink, subIndex) => (
                                            <li className="mb-1 last:mb-0" key={subIndex}>
                                                <Link
                                                    href={subLink.url}
                                                    className="font-medium text-white text-20 leading-7 hover:text-i-gold transition-all"
                                                >
                                                    {subLink.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    ""
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default NavMenu;
