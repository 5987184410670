import sanitizeHTML from "./SanitizeHTML";
import React from "react";

const MoveWordsToNewLine = ({children}) => {
    function _objectWithoutPropertiesLoose(source, excluded) {
        if (source == null) return {};
        var target = {};
        var sourceKeys = Object.keys(source);
        var key, i;
      
        for (i = 0; i < sourceKeys.length; i++) {
          key = sourceKeys[i];
          if (excluded.indexOf(key) >= 0) continue;
          target[key] = source[key];
        }
      
        return target;
    }

    function _objectWithoutProperties(source, excluded) {
        if (source == null) return {};
      
        var target = _objectWithoutPropertiesLoose(source, excluded);
      
        var key, i;
      
        if (Object.getOwnPropertySymbols) {
          var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
      
          for (i = 0; i < sourceSymbolKeys.length; i++) {
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
          }
        }
      
        return target;
    }


    function handleDangerouslySetInnerHTML(children) {
        if (children && children.props && children.props.dangerouslySetInnerHTML) {
        children.props.dangerouslySetInnerHTML.__html = moveWordsToNewLine(children.props.dangerouslySetInnerHTML.__html);
        }
    }
  

    const moveWordsToNewLine = (text) => {
        handleDangerouslySetInnerHTML(text);
        if (typeof text === 'string') {
            const lettersToReplace = ['i', 'lub', 'że', 'bo', 'aż', 'z', 'w', 'na', 'od', 'do', 'za', 'pod', 'nad', 'o', 'u', 'z', 'a'];
            lettersToReplace.forEach(el => {
                const regex = new RegExp(` (${el}) `, 'ig');
                text = text.replaceAll(regex, ' $1&nbsp;')
            });
            text = text.replace(/&lt;/gi, str => '<');
            text = text.replace(/&gt;/gi, str => '>');

            return sanitizeHTML(text);
        }
        else if (typeof text === 'number') {
            return sanitizeHTML(text.toString());
        } else if (typeof text === 'object' && text.hasOwnProperty('props')) {
            var _child$props = text.props,
            _children = _child$props.children,
            props = _objectWithoutProperties(_child$props, ["children"]);


            return _children ? React.cloneElement(text, props, moveWordsToNewLine(_children)) : text;
        }

        return React.Children.map(text, moveWordsToNewLine);
    }
      
    return moveWordsToNewLine(children)

}

export default MoveWordsToNewLine;